import * as THREE from "three";
import ProductNode from "./ProductNode";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

class DibondNode extends ProductNode {
  edgeMaterial = new THREE.MeshStandardMaterial();

  private bakedShadowPlane:
    | THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>
    | undefined;

  public constructor() {
    super();
  }

  public async setup() {
    // TODO: could eventually accept Artwork (config) and do it's own set up accordingly
    //
    // Load canvas model
    //
    const loader = new GLTFLoader();
    let dibondModel = await loader.loadAsync("/models/dibond.glb");
    this.baseNode = dibondModel.scene;
    // this.baseNode.position.z = 0.001;
    this.baseNode.children[0].children.forEach((child) => {
      if (child instanceof THREE.Mesh) {
        if (child.name === "Cube") {
          // let mat = child.material as THREE.MeshStandardMaterial;
          // this.artworkMaterial = mat;
          // mat.metalness = 0.0;
          // mat.roughness = 0.4;
          // mat.map = null;
          // console.log(child.material);
          child.scale.y = -1; // Hack because UV map is upside down...
        } else if (child.name === "Cube_1") {
          // let mat = child.material as THREE.MeshStandardMaterial;
          // this.edgeMaterial = mat;
        }
      }
    });

    this.baseNode.children.forEach((parent) => {
      if (parent.name === "Dibond") {
        parent.children.forEach((child) => {
          if (child instanceof THREE.Mesh) {
            if (child.name === "Cube") {
              let mat = new THREE.MeshPhysicalMaterial();
              child.material = mat;
              mat.specularColor = new THREE.Color(0, 0, 0);
              this.artworkMaterial = mat;
              mat.metalness = 0.0;
              mat.roughness = 0.4;
              mat.map = null;

              // console.log(child.material);
              child.scale.y = -1; // Hack because UV map is upside down...
            } else if (child.name === "Cube_1") {
              // let mat = child.material as THREE.MeshStandardMaterial;
              // this.edgeMaterial = mat;
            }
          }
        });
      } else if (parent.name === "Support") {
        if (parent instanceof THREE.Mesh) {
          let mat = parent.material as THREE.MeshStandardMaterial;
          this.edgeMaterial = mat;
        }
      }
    });

    //
    // Baked shadow
    //
    this.bakedShadowPlane = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshBasicMaterial()
    );
    let bakedShadowTexture = await new THREE.TextureLoader().loadAsync(
      "/textures/ShadowBake.png"
    );
    bakedShadowTexture.encoding = THREE.sRGBEncoding;
    this.bakedShadowPlane.material.map = bakedShadowTexture;
    this.bakedShadowPlane.material.transparent = true;
    this.bakedShadowPlane.material.opacity = 0.6;
    this.bakedShadowPlane.scale.set(2 * 0.97 * 0.99, 2 * 0.97 * 0.99, 1);
    this.add(this.bakedShadowPlane);

    this.add(this.baseNode);
  }

  public setDimensions(width: number, height: number) {
    this.scale.x = width;
    this.scale.y = height;

    this.bakedShadowPlane?.scale.set(
      (this.flipShadow ? -1 : 1) * 2 * 0.97 * 0.99,
      2 * 0.97 * 0.99,
      1
    );
  }
}

export default DibondNode;
