import { Vector2 } from "three";
import { Scene } from "./scene";

export enum ContentMode {
  AspectScaleToFill = "aspectScaleToFill",
  AspectScaleToFit = "aspectScaleToFit",
}

export enum CanvasEdgeFinish {
  White = "white",
  Black = "black",
  ImageWrap = "imageWrap",
  MirrorWrap = "mirrorWrap",
}

export enum DibondEdgeFinish {
  White = "white",
  Black = "black",
}

export enum FrameMaterial {
  LightWood = "natural",
  DarkWood = "darkWood", // Deprecated
  WhiteWood = "white",
  BlackWood = "black",
  Silver = "silver",
  Gold = "gold",
  WhiteMetallic = "whiteMetallic",
  BlackMetallic = "blackMetallic",
  RedMetallic = "redMetallic",
  BlueMetallic = "blueMetallic",
  LightestWoodGrain = "lightestWoodGrain",
  BlackWoodGrain = "blackWoodGrain",
  GrayWoodGrain = "grayWoodGrain",
  WalnutWoodGrain = "walnutWoodGrain",
}

export interface Artwork {
  imagePath: string;
  imageSource: string;
  size: Vector2;
  kind: ArtworkKind;
  contentMode: ContentMode;
}

export enum ArtworkKind {
  Print = "print",
  Framed = "framed",
  Canvas = "canvas",
  Dibond = "dibond",
  FloatingCanvas = "floatingCanvas",
  FloatingDibond = "floatingDibond",
  BoxFrame = "boxFrame",
  Image = "image",
}

export interface PrintArtwork extends Artwork {
  kind: ArtworkKind.Print;
}

export interface CanvasArtwork extends Artwork {
  kind: ArtworkKind.Canvas;
  edgeFinish: CanvasEdgeFinish;
  depth: number;
}

export interface DibondArtwork extends Artwork {
  kind: ArtworkKind.Dibond;
  edgeFinish: DibondEdgeFinish;
}

export interface FramedArtwork extends Artwork {
  kind: ArtworkKind.Framed;
  frameThickness: Vector2;
  frameMaterial: FrameMaterial;
  isMounted: boolean;
  mountInset: Vector2; // If you want it to be proportionate, you can do the maths yourself
}

export interface Configuration {
  scene: Scene;
  artwork: Artwork;
  renderSize: Vector2;
  ultraHighResolution?: boolean;
}
