import React, { Component } from "react";
import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { DragControls, clampBoxInBox } from "./DragControls.js";
import Compressor from "compressorjs";
// import { ThisExpression } from "typescript";

// import { Scene as StarboardScene } from "./models/scene";
import {
  Configuration,
  ArtworkKind,
  FramedArtwork,
  FrameMaterial,
  CanvasEdgeFinish,
  CanvasArtwork,
  DibondEdgeFinish,
  DibondArtwork,
} from "./models/configuration";

import ProductNode from "./ProductNode";
import CanvasNode from "./CanvasNode";
import DibondNode from "./DibondNode";
import FloatingCanvasNode from "./FloatingCanvasNode";
import FloatingDibondNode from "./FloatingDibondNode";
import FrameNode from "./FrameNode";
import BoxFrameNode from "./BoxFrameNode";
import PrintNode from "./PrintNode";
import ImageNode from "./ImageNode";
import { Vector3, Box2 } from "three";

// import FSpyCameraLoader from "three-fspy-camera-loader";

interface StarboardProps {
  config: Configuration;
  captureMode: boolean;
  ultraHighResolution: boolean;
  allowDrag: boolean;
  watermarkLevel: number;
  onFinishLoading: any;
  contextWasLostAction: any; // TODO: is there no way to fix the resolve context issue internally to Starboard? e.g. https://developer.mozilla.org/en-US/docs/Web/API/Resize_Observer_API
}

class Starboard extends Component<StarboardProps> {
  mount: any;
  scene!: THREE.Scene;
  camera!: THREE.PerspectiveCamera;
  renderer!: THREE.WebGLRenderer;
  backgroundPlane = new THREE.Mesh(
    new THREE.PlaneGeometry(1, 1),
    new THREE.MeshBasicMaterial()
  );
  productNode!: ProductNode;
  artworkTexture: THREE.Texture | undefined;
  readyForConfig = false;
  assetsLoaded = false;
  controls!: DragControls;
  finishedLoadingBackgroundTexture = false;
  finishedLoadingArtworkTexture = false;
  finishedLoadingProductNode = false;
  safeArea: THREE.Box2 | undefined;
  safeAreaPlane!: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>;

  constructor(props: StarboardProps | Readonly<StarboardProps>) {
    super(props);
    this.state = {
      isLoadingProductNode: false,
      isLoadingArtwork: false,
      isLoadingScene: false,
    };
  }

  handleContextLost(event: any) {
    // console.log("context restored!");
    // parentThis.resizeCanvasToDisplaySize();
    this.props.contextWasLostAction();
  }

  async componentDidMount() {
    const width = this.mount.clientWidth; // TODO: see where this is set in resizeCanvasToDisplaySize too
    const height = this.mount.clientHeight;

    //ADD SCENE
    this.scene = new THREE.Scene();

    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(27.4, width / height, 0.01, 100);
    this.scene.add(this.camera);
    this.camera.position.z = 4;

    //
    // Add HDRI
    //
    let envMap = await new RGBELoader().loadAsync(
      "/textures/living-room-1-tiny-x5.hdr"
    );
    envMap.mapping = THREE.EquirectangularReflectionMapping;
    this.scene.background = envMap;
    this.scene.environment = envMap;

    //
    // Create renderer
    //
    if (this.renderer) {
      // Prevent duplicate canvases in dev mode...
      return;
    }
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(window.devicePixelRatio); // Possibly want to turn this off if cloud rendering?
    this.mount?.appendChild(this.renderer.domElement);

    // Force scene re-render when WebGL context is restored
    // Context can be lost when returning to the browser after a long time, leaving a blank canvas
    // Note: At time of writing this has not been tested -> this never worked
    // this.renderer.domElement.addEventListener("webglcontextrestored", () => {
    //   this.renderScene();
    // });

    //
    // Add background plane
    //
    this.backgroundPlane.material.depthTest = false;
    this.backgroundPlane.renderOrder = -3;
    this.camera.add(this.backgroundPlane);

    if (this.props.watermarkLevel > 0) {
      const watermarkPlane = new THREE.Mesh(
        new THREE.PlaneGeometry(1, 1),
        new THREE.MeshBasicMaterial()
      );
      watermarkPlane.material.depthTest = false;
      watermarkPlane.material.transparent = true;
      watermarkPlane.renderOrder = -3;
      let watermarkTexture = await new THREE.TextureLoader().loadAsync(
        this.props.watermarkLevel === 1
          ? "textures/watermark.png"
          : "textures/watermark-repeat.png"
      );
      watermarkPlane.material.map = watermarkTexture;
      watermarkPlane.material.needsUpdate = true;
      this.backgroundPlane.add(watermarkPlane);
    }

    //
    // Add safe area plane
    //
    this.safeAreaPlane = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshBasicMaterial()
    );
    // this.safeAreaPlane.material.depthTest = true;
    this.safeAreaPlane.renderOrder = -2;
    this.safeAreaPlane.material.color = new THREE.Color(1, 1, 1);
    this.safeAreaPlane.material.transparent = false;
    this.safeAreaPlane.material.opacity = 0.3;
    this.safeAreaPlane.visible = false;
    this.scene.add(this.safeAreaPlane);

    //
    // Add drag controls
    //
    if (this.props.allowDrag || this.props.allowDrag === undefined) {
      this.controls = new DragControls(
        [],
        this.camera,
        this.renderer.domElement,
        this.safeArea,
        (isSelected: boolean) => {
          const showPlane = isSelected && !!this.safeArea;
          this.safeAreaPlane.material.transparent = showPlane;
          this.safeAreaPlane.visible = showPlane;
          this.safeAreaPlane.material.needsUpdate = true;
          this.renderScene();
        }
      );
      this.controls.addEventListener("drag", this.renderScene);
    }
    // console.log("DRAG CONTROLS INIT");

    // let sphere = new THREE.Mesh(
    //   new THREE.SphereGeometry(0.1),
    //   new THREE.MeshStandardMaterial({ roughness: 0.0, metalness: 1.0 })
    // );
    // this.scene.add(sphere);

    //
    // Debug grid
    //
    // let debugGrid = new THREE.GridHelper();
    // debugGrid = new THREE.GridHelper(10, 20, new THREE.Color(1, 0, 0));
    // debugGrid.rotation.x = Math.PI / 2;
    // this.scene.add(debugGrid);

    // console.log("Reached end of componentDidMount");
    this.readyForConfig = true;

    this.renderer.domElement.addEventListener(
      "webglcontextlost",
      this.handleContextLost.bind(this)
    );

    window.addEventListener(
      "resize",
      this.resizeCanvasToDisplaySize.bind(this)
    );
    this.resizeCanvasToDisplaySize();

    this.start();
  }
  async componentDidUpdate(prevProps: StarboardProps) {
    // console.log("componentDidUpdate:");
    // console.log(this.props.config);

    if (prevProps.captureMode !== this.props.captureMode) {
      this.updateCanvasSize(this.props.captureMode);
    }

    if (!prevProps || prevProps.config !== this.props.config) {
      // console.log("componentDidUpdate -> loadConfig");
      await this.loadConfig(prevProps.config);
    }
  }

  async loadConfig(prevConfig: Configuration | null) {
    if (!this.props.config.scene) {
      return;
    }

    const thisConfig = this.props.config;

    // console.log(
    //   "artwork.size: " + JSON.stringify(this.props.config.artwork.size)
    // );

    // console.log(
    //   "SETTING CONFIG with size: " +
    //     config.artwork.size.x +
    //     ", " +
    //     config.artwork.size.y
    // );

    // console.log("Resetting asset load status");
    this.assetsLoaded = false;

    //
    // Background texture
    //
    if (
      !prevConfig ||
      prevConfig.scene?.backgroundTexture !==
        this.props.config.scene.backgroundTexture
    ) {
      this.finishedLoadingBackgroundTexture = false;
      this.setState({ isLoadingScene: true });
      let backgroundTexture = await new THREE.TextureLoader().loadAsync(
        this.props.config.scene.backgroundTexture
      );
      backgroundTexture.encoding = THREE.sRGBEncoding;
      if (
        thisConfig.scene.backgroundTexture !==
        this.props.config.scene.backgroundTexture
      ) {
        // console.log("Race condition averted");
        // TODO: loadConfig needs revisiting for race conditions. also should cancel downloads if no longer needed.
        return;
      }
      this.backgroundPlane.material.map = backgroundTexture;
      // this.backgroundPlane.material.color = new THREE.Color("#ccc");
      this.backgroundPlane.material.needsUpdate = true;
      this.finishedLoadingBackgroundTexture = true;
    }

    let shouldKeepProductPosition = false;
    if (prevConfig && prevConfig.scene?.id === this.props.config.scene.id) {
      shouldKeepProductPosition = true;
    }

    //
    // Load product node
    //
    let previousProductPosition =
      this.productNode?.position ?? new Vector3(0, 0, 0);
    // Don't reload product node unnecessarily
    if (
      !prevConfig ||
      this.props.config.artwork.kind !== prevConfig?.artwork?.kind
    ) {
      this.finishedLoadingProductNode = false;
      if (this.productNode) {
        this.productNode.removeFromParent();
      }
      if (this.props.config.artwork.kind === ArtworkKind.Framed) {
        this.productNode = new FrameNode();
      } else if (this.props.config.artwork.kind === ArtworkKind.Canvas) {
        this.productNode = new CanvasNode();
      } else if (this.props.config.artwork.kind === ArtworkKind.Dibond) {
        this.productNode = new DibondNode();
      } else if (
        this.props.config.artwork.kind === ArtworkKind.FloatingCanvas
      ) {
        this.productNode = new FloatingCanvasNode();
      } else if (
        this.props.config.artwork.kind === ArtworkKind.FloatingDibond
      ) {
        this.productNode = new FloatingDibondNode();
      } else if (this.props.config.artwork.kind === ArtworkKind.BoxFrame) {
        this.productNode = new BoxFrameNode();
      } else if (this.props.config.artwork.kind === ArtworkKind.Print) {
        this.productNode = new PrintNode();
      } else {
        this.productNode = new ImageNode();
      }
      this.setState({ isLoadingProductNode: true });

      // console.log("start productNode.setup()");
      await this.productNode.setup();
      // console.log("finish productNode.setup()");
      this.finishedLoadingProductNode = true;
    }

    if (this.productNode == null) {
      return;
    }

    if (shouldKeepProductPosition && previousProductPosition) {
      this.productNode.position.copy(previousProductPosition);
    } else {
      const artworkPosition = this.props.config.scene.artworkPosition
        ? new Vector3(
            this.props.config.scene.artworkPosition.x,
            this.props.config.scene.artworkPosition.y,
            this.props.config.scene.artworkPosition.z
          )
        : new Vector3(0, 0, 0);
      this.productNode.position.copy(artworkPosition);
    }
    const artworkRotation = this.props.config.scene.artworkRotation
      ? new Vector3(
          this.props.config.scene.artworkRotation.x, //  + 3.1415 / 2
          this.props.config.scene.artworkRotation.y, //  + 3.1415 / 2
          this.props.config.scene.artworkRotation.z
        )
      : new Vector3(0, 0, 0);
    this.productNode.rotation.set(
      artworkRotation.x,
      artworkRotation.y,
      artworkRotation.z,
      "ZYX"
    );
    this.controls?.artworkPosition.copy(this.productNode.position);
    this.controls?.artworkRotation.copy(this.productNode.rotation);
    this.controls?.updatePlane(this.controls);

    // Configure product
    if (this.props.config.artwork.kind === ArtworkKind.Framed) {
      let framedArtwork = this.props.config.artwork as FramedArtwork;
      let frameNode = this.productNode as FrameNode;

      switch (framedArtwork.frameMaterial) {
        case FrameMaterial.BlackWood: {
          frameNode.frameMaterial.color = new THREE.Color("#060606");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.WhiteWood: {
          frameNode.frameMaterial.color = new THREE.Color("#e4e4e4");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.LightWood: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lighter.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.DarkWood: {
          frameNode.frameMaterial.color = new THREE.Color("#C1A382");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lighter.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.Gold: {
          frameNode.frameMaterial.color = new THREE.Color("#c08d23");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.Silver: {
          frameNode.frameMaterial.color = new THREE.Color("#bbbdbd");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.BlackMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#080808");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.WhiteMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.2;
          break;
        }
        case FrameMaterial.RedMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#941a1c");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.BlueMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#a5c8df");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.LightestWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lightest.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.BlackWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-black.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.GrayWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-gray.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.WalnutWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-walnut.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
      }
      frameNode.isMounted = framedArtwork.isMounted;
      if (frameNode.frameMaterial.map) {
        frameNode.frameMaterial.map.encoding = THREE.sRGBEncoding;
      }
      frameNode.frameMaterial.needsUpdate = true;
    } else if (this.props.config.artwork.kind === ArtworkKind.BoxFrame) {
      let framedArtwork = this.props.config.artwork as FramedArtwork;
      let boxFrameNode = this.productNode as BoxFrameNode;

      switch (framedArtwork.frameMaterial) {
        case FrameMaterial.BlackWood: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#060606");
          boxFrameNode.frameMaterial.map = null;
          boxFrameNode.frameMaterial.metalness = 0.0;
          boxFrameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.WhiteWood: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#e4e4e4");
          boxFrameNode.frameMaterial.map = null;
          boxFrameNode.frameMaterial.metalness = 0.0;
          boxFrameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.LightWood: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#ffffff");
          boxFrameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lighter.jpg"
            );
          boxFrameNode.frameMaterial.metalness = 0.0;
          boxFrameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.DarkWood: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#C1A382");
          boxFrameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lighter.jpg"
            );
          boxFrameNode.frameMaterial.metalness = 0.0;
          boxFrameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.Gold: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#c08d23");
          boxFrameNode.frameMaterial.map = null;
          boxFrameNode.frameMaterial.metalness = 1.0;
          boxFrameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.Silver: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#bbbdbd");
          boxFrameNode.frameMaterial.map = null;
          boxFrameNode.frameMaterial.metalness = 1.0;
          boxFrameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.BlackMetallic: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#080808");
          boxFrameNode.frameMaterial.map = null;
          boxFrameNode.frameMaterial.metalness = 1.0;
          boxFrameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.WhiteMetallic: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#ffffff");
          boxFrameNode.frameMaterial.map = null;
          boxFrameNode.frameMaterial.metalness = 1.0;
          boxFrameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.RedMetallic: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#941a1c");
          boxFrameNode.frameMaterial.map = null;
          boxFrameNode.frameMaterial.metalness = 1.0;
          boxFrameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.BlueMetallic: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#a5c8df");
          boxFrameNode.frameMaterial.map = null;
          boxFrameNode.frameMaterial.metalness = 1.0;
          boxFrameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.LightestWoodGrain: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#ffffff");
          boxFrameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lightest.jpg"
            );
          boxFrameNode.frameMaterial.metalness = 0.0;
          boxFrameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.BlackWoodGrain: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#ffffff");
          boxFrameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-black.jpg"
            );
          boxFrameNode.frameMaterial.metalness = 0.0;
          boxFrameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.GrayWoodGrain: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#ffffff");
          boxFrameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-gray.jpg"
            );
          boxFrameNode.frameMaterial.metalness = 0.0;
          boxFrameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.WalnutWoodGrain: {
          boxFrameNode.frameMaterial.color = new THREE.Color("#ffffff");
          boxFrameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-walnut.jpg"
            );
          boxFrameNode.frameMaterial.metalness = 0.0;
          boxFrameNode.frameMaterial.roughness = 0.4;
          break;
        }
      }
      if (boxFrameNode.frameMaterial.map) {
        boxFrameNode.frameMaterial.map.encoding = THREE.sRGBEncoding;
      }
      boxFrameNode.frameMaterial.needsUpdate = true;
    } else if (this.props.config.artwork.kind === ArtworkKind.FloatingCanvas) {
      let framedArtwork = this.props.config.artwork as FramedArtwork;
      let frameNode = this.productNode as FloatingCanvasNode;

      switch (framedArtwork.frameMaterial) {
        case FrameMaterial.BlackWood: {
          frameNode.frameMaterial.color = new THREE.Color("#060606");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.WhiteWood: {
          frameNode.frameMaterial.color = new THREE.Color("#e4e4e4");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.LightWood: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lighter.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.DarkWood: {
          frameNode.frameMaterial.color = new THREE.Color("#C1A382");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lighter.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.Gold: {
          frameNode.frameMaterial.color = new THREE.Color("#c08d23");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.Silver: {
          frameNode.frameMaterial.color = new THREE.Color("#bbbdbd");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.BlackMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#080808");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.WhiteMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.RedMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#941a1c");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.BlueMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#a5c8df");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.LightestWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lightest.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.BlackWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-black.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.GrayWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-gray.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.WalnutWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-walnut.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
      }
      if (frameNode.frameMaterial.map) {
        frameNode.frameMaterial.map.encoding = THREE.sRGBEncoding;
      }
      frameNode.frameMaterial.needsUpdate = true;
    } else if (this.props.config.artwork.kind === ArtworkKind.FloatingDibond) {
      let framedArtwork = this.props.config.artwork as FramedArtwork;
      let frameNode = this.productNode as FloatingDibondNode;

      switch (framedArtwork.frameMaterial) {
        case FrameMaterial.BlackWood: {
          frameNode.frameMaterial.color = new THREE.Color("#060606");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.WhiteWood: {
          frameNode.frameMaterial.color = new THREE.Color("#e4e4e4");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.LightWood: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lighter.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.DarkWood: {
          frameNode.frameMaterial.color = new THREE.Color("#C1A382");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lighter.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.Gold: {
          frameNode.frameMaterial.color = new THREE.Color("#c08d23");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.2;
          break;
        }
        case FrameMaterial.Silver: {
          frameNode.frameMaterial.color = new THREE.Color("#bbbdbd");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.2;
          break;
        }
        case FrameMaterial.BlackMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#080808");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.WhiteMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.RedMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#941a1c");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.BlueMetallic: {
          frameNode.frameMaterial.color = new THREE.Color("#a5c8df");
          frameNode.frameMaterial.map = null;
          frameNode.frameMaterial.metalness = 1.0;
          frameNode.frameMaterial.roughness = 0.08;
          break;
        }
        case FrameMaterial.LightestWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-lightest.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.BlackWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-black.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.GrayWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-gray.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
        case FrameMaterial.WalnutWoodGrain: {
          frameNode.frameMaterial.color = new THREE.Color("#ffffff");
          frameNode.frameMaterial.map =
            await new THREE.TextureLoader().loadAsync(
              "/textures/natural-wood-walnut.jpg"
            );
          frameNode.frameMaterial.metalness = 0.0;
          frameNode.frameMaterial.roughness = 0.4;
          break;
        }
      }
      if (frameNode.frameMaterial.map) {
        frameNode.frameMaterial.map.encoding = THREE.sRGBEncoding;
      }
      frameNode.frameMaterial.needsUpdate = true;
    } else if (this.props.config.artwork.kind === ArtworkKind.Canvas) {
      // Any canvas-specific config to go here
      let canvasArtwork = this.props.config.artwork as CanvasArtwork;
      let canvasNode = this.productNode as CanvasNode;

      // console.log(canvasArtwork.edgeFinish);
      switch (canvasArtwork.edgeFinish) {
        case CanvasEdgeFinish.White: {
          canvasNode.edgeMaterial.color = new THREE.Color("#e2e2e2");
          break;
        }
        case CanvasEdgeFinish.Black: {
          canvasNode.edgeMaterial.color = new THREE.Color("#0f0f0f");
          break;
        }
      }
      canvasNode.edgeMaterial.needsUpdate = true;
      // console.log(canvasNode.edgeMaterial.color);
    } else if (this.props.config.artwork.kind === ArtworkKind.Dibond) {
      // Any canvas-specific config to go here
      let dibondArtwork = this.props.config.artwork as DibondArtwork;
      let dibondNode = this.productNode as DibondNode;

      // console.log(canvasArtwork.edgeFinish);
      switch (dibondArtwork.edgeFinish) {
        case DibondEdgeFinish.White: {
          dibondNode.edgeMaterial.color = new THREE.Color("#e2e2e2");
          break;
        }
        case DibondEdgeFinish.Black: {
          dibondNode.edgeMaterial.color = new THREE.Color("#0f0f0f");
          break;
        }
      }
      dibondNode.edgeMaterial.needsUpdate = true;
      // console.log(dibondNode.edgeMaterial.color);
    } else {
      // Any print-specific config to go here
    }

    // Update drag controller
    if (this.controls) {
      this.controls.objects = [this.productNode];
    }

    //
    // Artwork
    //
    // - Texture
    // this.props.config.artwork.imagePath =
    // "https://cdn.shopify.com/s/files/1/0574/7507/2209/products/886ac2ed-a106-4782-b663-1ebb12df368f-l.jpg?v=1680786306";
    // "https://cdn.shopify.com/s/files/1/0574/7507/2209/products/886ac2ed-a106-4782-b663-1ebb12df368f-l_20x20.jpg?v=1680786306";
    if (this.props.config.artwork.imagePath) {
      if (
        !prevConfig ||
        prevConfig.artwork?.imagePath !== this.props.config.artwork.imagePath
      ) {
        // console.log(
        //   "Loading artwork texture because imagePath is different, or this is the first config"
        // );
        this.finishedLoadingArtworkTexture = false;
        this.setState({ isLoadingArtwork: true });

        // Fetch image
        let fetchedImage = await fetch(this.props.config.artwork.imagePath);
        let imageBlob = await fetchedImage.blob();
        // Run through Compressor... as a crude way to normalise the image's colour space
        const result = await new Promise((resolve, reject) => {
          new Compressor(imageBlob, {
            success: resolve,
            error: reject,
          });
        });
        let imageData = await blobToData(result as any);

        // Load texture
        this.artworkTexture = await new THREE.TextureLoader().loadAsync(
          imageData as any
        );
        this.artworkTexture.encoding = THREE.sRGBEncoding;

        // console.log(this.artworkTexture.format);
        // console.log(this.artworkTexture.type);
        // console.log(this.artworkTexture.encoding);
        // console.log((this.artworkTexture as any).colorSpace);
        // (this.artworkTexture as any).colorSpace = "srgb";

        this.finishedLoadingArtworkTexture = true;
      }
    } else if (this.props.config.artwork.imageSource) {
      if (
        !prevConfig ||
        prevConfig.artwork?.imageSource !==
          this.props.config.artwork.imageSource
      ) {
        this.finishedLoadingArtworkTexture = false;
        let image = new Image();
        image.src = this.props.config.artwork.imageSource;
        this.setState({ isLoadingArtwork: true });
        await image.decode();
        this.artworkTexture = new THREE.Texture();
        this.artworkTexture.image = image;
        this.artworkTexture.magFilter = THREE.LinearFilter;
        this.artworkTexture.minFilter = THREE.LinearMipmapLinearFilter;
        this.artworkTexture.encoding = THREE.sRGBEncoding;
        // this.artworkTexture.anisotropy = this.renderer.getMaxAnisotropy();

        // console.log(this.artworkTexture.format);
        // console.log(this.artworkTexture.type);
        // console.log(this.artworkTexture.encoding);
        // console.log((this.artworkTexture as any).colorSpace);
        // (this.artworkTexture as any).colorSpace = "srgb";

        this.artworkTexture.needsUpdate = true;
        this.finishedLoadingArtworkTexture = true;
      }
    } else {
      this.artworkTexture = undefined;
      // this.artworkTexture.needsUpdate = true;
    }
    if (
      this.productNode.artworkMaterial &&
      this.productNode.artworkMaterial!.map !== this.artworkTexture
    ) {
      this.productNode.artworkMaterial!.map = this.artworkTexture || null;
      this.productNode.artworkMaterial!.needsUpdate = true;
    }
    // TODO: remove this prototype code block
    if (this.productNode.artworkMaterial) {
      if (
        this.props.config.scene.id === "bedroom-1" ||
        this.props.config.scene.id === "bedroom-2"
      ) {
        this.productNode.artworkMaterial!.color = new THREE.Color(
          1,
          1,
          1
        ).multiplyScalar(0.9);
      } else {
        this.productNode.artworkMaterial!.color = new THREE.Color(1, 1, 1);
      }
    }

    if (this.productNode.artworkMaterial?.map) {
      this.productNode.applyTextureContentModeTransform(
        this.productNode.artworkMaterial?.map,
        this.props.config.artwork.size,
        this.props.config.artwork.contentMode
      );
    }

    // TODO: Need a proper way to handle what we show when we don't have an artwork image, e.g. placeholder, loading indicator, error message
    if (
      this.props.config.artwork.imageSource ||
      this.props.config.artwork.imagePath
    ) {
      this.scene.add(this.productNode);
    }

    // - Size
    // console.log(
    //   "applying size to productNode: " +
    //     this.props..artwork.size.x +
    //     ", " +
    //     this.props..artwork.size.y
    // );

    // if (
    //   this.props.config.artwork.size.x !== config.artwork.size.x ||
    //   this.props.config.artwork.size.y !== config.artwork.size.y
    // ) {
    //   console.log("XXXXXXXXXX mismatch!");
    // }

    // Flip product's artificial shadow if scene requires it
    this.productNode.flipShadow = this.props.config.scene.flipShadow;

    this.productNode.setDimensions(
      this.props.config.artwork.size.x,
      this.props.config.artwork.size.y
    );

    // Set canvas size based on props, fallback to DOM element
    // const aspectRatio = this.props.config.scene.aspectRatio ?? 1;
    // const width =
    //   this.props.config.renderSize?.width ??
    //   this.mount.clientWidth * aspectRatio ??
    //   0;
    // const height =
    //   this.props.config.renderSize?.height ?? this.mount.clientHeight;
    // this.setCanvasSize(width, height, window.devicePixelRatio);
    this.updateCanvasSize();

    // // TESTING
    // if (config.scene.id === "cafe-1") {
    //   this.productNode.position.x = 0;
    //   this.productNode.position.y = 0.05;
    // } else {
    //   this.productNode.position.x = 0.0;
    //   this.productNode.position.y = 0.0;
    // }

    //
    // Camera
    //
    let cameraPositionZ = this.props.config.scene.cameraPosition.z;
    if (this.props.config.scene.artworkOnlyMode) {
      // Warning: this artworkOnlyMode code is not canvas aspect ratio aware. e.g. we assume same horizontal and vertical FOV.
      // Warning: requires scene config to have camera and artwork at zero pos and rot
      const artworkOnlyMode_artworkProportionOfCanvas =
        this.props.config.scene.artworkOnlyCanvasFillRatio ?? 0.5;
      const distance =
        Math.max(
          this.productNode.totalSize().x,
          this.productNode.totalSize().y
        ) /
        artworkOnlyMode_artworkProportionOfCanvas /
        2 /
        Math.tan((this.props.config.scene.cameraFov / 2) * (Math.PI / 180));
      cameraPositionZ = distance;
    }
    this.camera.position.set(
      this.props.config.scene.cameraPosition.x,
      this.props.config.scene.cameraPosition.y,
      cameraPositionZ
    );
    this.camera.rotation.set(
      this.props.config.scene.cameraRotation.x,
      this.props.config.scene.cameraRotation.y,
      this.props.config.scene.cameraRotation.z,
      "ZYX"
      // "XZY" // think this is not needed now I'm exporting the rotation in the appropriate order
    );
    if (this.props.config.scene.cameraFov) {
      this.camera.fov = this.props.config.scene.cameraFov; // TODO: will need to convert between Blender (width based) and Three.js (height based) FOVs once we support non-square scenes.
    } else {
      this.camera.fov = 27.4; // TODO: maybe don't have a default... but throw an error
    }
    this.camera.updateProjectionMatrix();

    //
    // Update background plane to fill the far frustum
    //
    const fovRad = (this.camera.fov / 180) * Math.PI;
    const h = Math.tan(fovRad / 2) * this.camera.far * 2;
    const w = h * this.camera.aspect;
    const d = this.camera.far;
    this.backgroundPlane.scale.set(w, h, 1);
    this.backgroundPlane.position.setZ(-d);

    if (this.props.config.scene.safeArea) {
      const safeArea = new THREE.Box2();
      console.log(this.props.config.scene.safeArea);
      safeArea.setFromPoints([
        new THREE.Vector2(
          this.props.config.scene.safeArea.min.x,
          this.props.config.scene.safeArea.min.y
        ),
        new THREE.Vector2(
          this.props.config.scene.safeArea.max.x,
          this.props.config.scene.safeArea.max.y
        ),
      ]);
      this.safeArea = safeArea;

      let safeAreaCenter = new THREE.Vector2(0, 0);
      safeArea.getCenter(safeAreaCenter);

      let safeAreaSize = new THREE.Vector2(0, 0);
      safeArea.getSize(safeAreaSize);

      this.safeAreaPlane.position.set(safeAreaCenter.x, safeAreaCenter.y, 0);
      this.safeAreaPlane.scale.set(safeAreaSize.x, safeAreaSize.y, 1);

      // Enforce bounds
      const productBounds = new Box2();
      productBounds.setFromCenterAndSize(
        new THREE.Vector2(
          this.productNode.position.x,
          this.productNode.position.y
        ),
        this.productNode.totalSize()
      );

      clampBoxInBox(productBounds, this.safeArea);
      let newCenter = new THREE.Vector2();
      productBounds.getCenter(newCenter);
      this.productNode.position.copy(
        new THREE.Vector3(newCenter.x, newCenter.y, 0)
      );
    } else {
      this.safeArea = undefined;
    }
    if (this.controls) {
      this.controls.safeArea = this.safeArea;
    }

    // console.log("h:");
    // console.log(h);
    // console.log("d:");
    // console.log(d);

    // let loader = new FSpyCameraLoader();
    // loader.load(
    //   "cafeSquareFspy.json",
    //   // onload
    //   (result) => {
    //     console.log("pos: " + JSON.stringify(result.position));
    //     console.log("rot: " + JSON.stringify(result.rotation.toVector3()));
    //     // this.camera = result as any;
    //   },
    //   // onprogress
    //   function (xhr) {
    //     //console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
    //   },
    //   // onerror
    //   function (error) {
    //     console.log("ERROR");
    //   }
    // );

    // console.log("Bottom of loadConfig reached, this is the current config:");
    // console.log(this.props.config);

    if (this.finishedLoadingBackgroundTexture) {
      this.setState({ isLoadingScene: false });
    }
    if (this.finishedLoadingArtworkTexture) {
      this.setState({ isLoadingArtwork: false });
    }
    if (this.finishedLoadingProductNode) {
      this.setState({ isLoadingProductNode: false });
    }

    // TODO: add finishedLoadingProductNode into this logic check too...
    // TODO: what if the texture doesn't need to change though? e.g. just changing artwork size
    // This is a super crude way to determine when external assets have finished downloading. Needs re-architecting but probably does the job for the bulk rendering MVP.
    // console.log(
    //   "this.props.config.artwork.imageSource: " +
    //     this.props.config.artwork.imageSource
    // );
    // console.log(
    //   "this.props.config.artwork.imagePath: " +
    //     this.props.config.artwork.imagePath
    // );
    // console.log("isLoadingScene: " + (this.state as any).isLoadingScene);
    // console.log("isLoadingArtwork: " + (this.state as any).isLoadingArtwork);
    // console.log(
    //   "isLoadingProductNode: " + (this.state as any).isLoadingProductNode
    // );
    // console.log(
    //   "finishedLoadingBackgroundTexture: " +
    //     this.finishedLoadingBackgroundTexture
    // );
    // console.log(
    //   "finishedLoadingArtworkTexture: " + this.finishedLoadingArtworkTexture
    // );
    // console.log(
    //   "finishedLoadingProductNode: " + this.finishedLoadingProductNode
    // );
    if (
      (this.props.config.artwork.imageSource ||
        this.props.config.artwork.imagePath) &&
      !(this.state as any).isLoadingArtwork &&
      !(this.state as any).isLoadingScene &&
      !(this.state as any).isLoadingProductNode &&
      (this.finishedLoadingBackgroundTexture ||
        this.finishedLoadingArtworkTexture ||
        this.finishedLoadingProductNode)
    ) {
      // console.log("Artwork and scene textures have been loaded");

      // console.log("Assets loaded");
      this.assetsLoaded = true;
    }

    this.renderScene();

    if (this.props.onFinishLoading && this.assetsLoaded) {
      this.props.onFinishLoading();
    }

    // }
  }
  componentWillUnmount() {
    // console.log("removing domElement and event listeners");
    window.removeEventListener(
      "resize",
      this.resizeCanvasToDisplaySize.bind(this)
    );
    if (this.renderer?.domElement) {
      this.renderer.domElement.removeEventListener(
        "webglcontextlost",
        this.handleContextLost.bind(this),
        false
      );
      this.mount.removeChild(this.renderer.domElement);
    }
  }
  start = () => {
    // console.log("start -> loadConfig");
    this.loadConfig(null);
  };
  renderScene = () => {
    // this.resizeCanvasToDisplaySize();
    this.renderer?.render(this.scene, this.camera);

    // console.log("Context:");
    // console.log(this.renderer.getContext().isContextLost());

    // console.log("Rendering scene");
    if (this.assetsLoaded) {
      // console.log("window.status = 'ready'");
      window.status = "ready";
    }
  };

  timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  resizeCanvasToDisplaySize = async () => {
    // console.log("RESIZING");

    if (this.mount) {
      // Artificial delay is needed here because otherwise the dimensions can be out of date and so the canvas doesn't fill the frame properly.
      // TODO: is there a way to respond to parent div size change rather than the window? maybe: https://developer.mozilla.org/en-US/docs/Web/API/Resize_Observer_API
      await this.timeout(1);

      this.updateCanvasSize();
    }
  };

  updateCanvasSize = (captureMode: boolean = false) => {
    const clientWidth = this.mount?.clientWidth ?? 0;
    const clientHeight = this.mount?.clientHeight ?? 0;

    let width;
    let height;
    let aspectRatio = this.props.config.scene.aspectRatio ?? 1;
    // console.log("Aspect ratio: " + aspectRatio);
    let longestEdge;
    if (captureMode) {
      const positiveAspectRatio =
        aspectRatio > 1 ? aspectRatio : 1 / aspectRatio;
      longestEdge = 1024 * (1 + (positiveAspectRatio - 1) / 2);
    } else {
      longestEdge = Math.max(clientWidth, clientHeight);
    }
    if (aspectRatio > 1.0) {
      width = longestEdge;
      height = longestEdge / aspectRatio;
    } else {
      width = longestEdge * aspectRatio;
      height = longestEdge;
    }

    if (width > clientWidth) {
      height = (height / width) * clientWidth;
      width = clientWidth;
    } else if (height > clientHeight) {
      width = (width / height) * clientHeight;
      height = clientHeight;
    }

    this.setCanvasSize(
      width,
      height,
      captureMode
        ? this.props.ultraHighResolution
          ? 4
          : 2
        : window.devicePixelRatio
    );
  };

  setCanvasSize = (width: number, height: number, pixelRatio: number) => {
    // console.log(`Setting canvas size: ${width} x ${height}`);

    this.renderer?.setSize(width, height, true);
    this.renderer?.setPixelRatio(pixelRatio);

    // TODO: I think this shouldn't be necessary... in theory... because the aspect ratio should be kept constant by the React page that integrates Starboard.
    // TODO: However... maybe when the page loads the canvas is being set to "100%" width and height but the parent hasn't had time to set up yet... or something...
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    // console.log("setCanvasSize calling renderScene");
    this.renderScene(); // TODO: consider if we can make less calls to this to boost performance. if you disable it entirely then you don't get the best resolution for the size, it's scaled.
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "#4E4E4E",
          opacity:
            (this.state as any).isLoadingArtwork ||
            (this.state as any).isLoadingScene ||
            this.props.config.artwork?.imageSource === null
              ? 0.35
              : 1.0,
        }}
        ref={(mount) => {
          this.mount = mount;
        }}
      ></div>
    );
  }
}

const blobToData = (blob: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export default React.memo(Starboard);
