import * as THREE from "three";
import ProductNode from "./ProductNode";

class PrintNode extends ProductNode {
  public constructor() {
    super();
  }

  public async setup() {
    // TODO: could eventually accept Artwork (config) and do it's own set up accordingly
    //
    // Load canvas model
    //
    this.baseNode = new THREE.Group();

    let box = new THREE.Mesh(
      new THREE.BoxGeometry(),
      new THREE.MeshPhysicalMaterial()
    );
    box.material.specularColor = new THREE.Color(0, 0, 0);
    this.artworkMaterial = box.material;

    this.baseNode.add(box);
    this.baseNode.position.z = 0.0005;
    this.baseNode.scale.z = 0.001;

    //
    // Baked shadow
    //
    let bakedShadowPlane = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshBasicMaterial()
    );
    let bakedShadowTexture = await new THREE.TextureLoader().loadAsync(
      "/textures/shadowPlanePrints.png"
    );
    bakedShadowTexture.encoding = THREE.sRGBEncoding;
    bakedShadowPlane.material.map = bakedShadowTexture;
    bakedShadowPlane.material.transparent = true;
    bakedShadowPlane.material.opacity = 0.2;
    bakedShadowPlane.scale.set(2, 2, 1);
    this.add(bakedShadowPlane);

    this.add(this.baseNode);
  }
}

export default PrintNode;
