import * as THREE from "three";
import { ContentMode } from "./models/configuration";

class ProductNode extends THREE.Group {
  baseNode: THREE.Group | undefined;
  artworkMaterial: THREE.MeshStandardMaterial | undefined;
  flipShadow: boolean | undefined;

  public constructor() {
    super();
  }

  public async setup() {}

  // Can be overridden with custom resizing logic if needed
  public setDimensions(width: number, height: number) {
    this.scale.x = width;
    this.scale.y = height;
  }

  // Can be overridden to account for frames etc if needed
  public totalSize() {
    return new THREE.Vector2(this.scale.x, this.scale.y);
  }

  public applyTextureContentModeTransform(
    texture: THREE.Texture,
    targetDimensions: THREE.Vector2,
    contentMode: ContentMode
  ) {
    const sourceDimensions = new THREE.Vector2(
      texture.image.naturalWidth,
      texture.image.naturalHeight
    );

    const sourceAspectRatio = sourceDimensions.x / sourceDimensions.y;
    const targetAspectRatio = targetDimensions.x / targetDimensions.y;

    // Note: If aspect ratio is equal then we can leave scale at default of {1, 1}
    let xScale = 1.0;
    let yScale = 1.0;
    if (contentMode === ContentMode.AspectScaleToFit) {
      if (sourceAspectRatio > targetAspectRatio) {
        yScale = targetAspectRatio / sourceAspectRatio;
      } else if (sourceAspectRatio < targetAspectRatio) {
        xScale = sourceAspectRatio / targetAspectRatio;
      }
    } else {
      if (sourceAspectRatio > targetAspectRatio) {
        xScale = sourceAspectRatio / targetAspectRatio;
      } else if (sourceAspectRatio < targetAspectRatio) {
        yScale = targetAspectRatio / sourceAspectRatio;
      }
    }

    texture.offset = new THREE.Vector2(
      (xScale - 1) / (2 * xScale),
      (yScale - 1) / (2 * yScale)
    );
    texture.repeat = new THREE.Vector2(1 / xScale, 1 / yScale);
  }
}

export default ProductNode;
