import * as THREE from "three";
import { Vector2 } from "three";
import ProductNode from "./ProductNode";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

class FloatingCanvasNode extends ProductNode {
  frameMaterial = new THREE.MeshStandardMaterial();
  // mountMaterial = new THREE.MeshStandardMaterial();
  baseNodeBounds = new Vector2();

  private morphableGeometries: MorphableGeometry[] = new Array();
  private bakedShadowPlane:
    | THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>
    | undefined;

  public constructor() {
    super();
  }

  public async setup() {
    // TODO: could eventually accept Artwork (config) and do it's own set up accordingly
    //
    // Load canvas model
    //
    const loader = new GLTFLoader();
    let frameModel = await loader.loadAsync("/models/floatingCanvas.glb");
    this.baseNode = frameModel.scene;
    this.baseNode.position.z = 0.001;
    this.baseNode.children.forEach((baseChild) => {
      baseChild.children.forEach((child) => {
        if (child instanceof THREE.Mesh) {
          this.morphableGeometries.push(
            new MorphableGeometry(child.geometry.attributes.position.array)
          );
          if (child.name === "Cube001") {
            // Base

            let mat = child.material as THREE.MeshStandardMaterial;
            mat.color = new THREE.Color("#4c4c4c");
            // this.artworkMaterial = mat;

            // let mat = child.material as THREE.MeshStandardMaterial;
            // mat.transparent = true;
            // mat.opacity = 0;

            child.scale.y = -1; // Hack because UV map is upside down...
            // } else if (child.name === "Cube001_1") {
            //   // Backside
            //   let mat = child.material as THREE.MeshStandardMaterial;
            //   mat.color = new THREE.Color("#ffffff");
          } else if (child.name === "Cube001_1") {
            // Frame
            child.material = this.frameMaterial;

            // Required in order to use aoMap (see threejs docs)
            child.geometry.setAttribute(
              "uv2",
              new THREE.BufferAttribute(child.geometry.attributes.uv.array, 2)
            );
          } else if (child.name === "Cube002") {
            // Canvas front
            let mat = new THREE.MeshPhysicalMaterial();
            child.material = mat;
            mat.specularColor = new THREE.Color(0, 0, 0);
            this.artworkMaterial = mat;
            mat.metalness = 0.0;
            mat.roughness = 0.4;
            mat.map = null;
            // mat.transparent = true;
            // mat.opacity = 0;
            child.scale.y = -1; // Hack because UV map is upside down...

            // Required in order to use aoMap (see threejs docs)
            child.geometry.setAttribute(
              "uv2",
              new THREE.BufferAttribute(child.geometry.attributes.uv.array, 2)
            );
          } else if (child.name === "Cube002_1") {
            // Canvas back
            let mat = child.material as THREE.MeshStandardMaterial;
            mat.color = new THREE.Color("#000000");
            // this.edgeMaterial = mat;
            // mat.transparent = true;
            // mat.opacity = 0;
          }
        }
      });
    });

    // Add inner baked shadows
    let innerBakedShadowTexture = await new THREE.TextureLoader().loadAsync(
      "/textures/floatingCanvas_frameBakedShadow.png"
    );
    innerBakedShadowTexture.encoding = THREE.sRGBEncoding;
    if (this.frameMaterial) {
      this.frameMaterial.aoMap = innerBakedShadowTexture;
      // this.frameMaterial.map = innerBakedShadowTexture;
      // this.frameMaterial.map.repeat.set(-1, 1);
      // this.frameMaterial.aoMapIntensity = 0.0;
      // TODO: maybe reinstate the above for shadow behind the canvas?
      // this.mountMaterial.aoMap = innerBakedShadowTexture; // HACK
      // this.mountMaterial.aoMap.wrapS = THREE.RepeatWrapping;
      // this.mountMaterial.aoMap.wrapT = THREE.RepeatWrapping;

      // this.frameMaterial.aoMap.repeat.set(1, 0.998);
      // this.frameMaterial.aoMap.offset.set(0.1, 0.1);

      this.frameMaterial.needsUpdate = true;
    }

    // Add inner baked shadows
    let canvasEdgeBakedShadowTexture =
      await new THREE.TextureLoader().loadAsync(
        "/textures/floatingCanvas_canvasBakedShadow.png"
      );
    canvasEdgeBakedShadowTexture.encoding = THREE.sRGBEncoding;
    if (this.artworkMaterial) {
      this.artworkMaterial.aoMap = canvasEdgeBakedShadowTexture;
      // this.frameMaterial.map = innerBakedShadowTexture;
      // this.frameMaterial.map.repeat.set(-1, 1);
      // this.frameMaterial.aoMapIntensity = this.isMounted ? 0.3 : 1;
      // TODO: maybe reinstate the above for shadow behind the canvas?
      // this.mountMaterial.aoMap = innerBakedShadowTexture; // HACK
      // this.mountMaterial.aoMap.wrapS = THREE.RepeatWrapping;
      // this.mountMaterial.aoMap.wrapT = THREE.RepeatWrapping;

      this.artworkMaterial.needsUpdate = true;
    }

    //
    // Baked shadow
    //
    this.bakedShadowPlane = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshBasicMaterial()
    );
    let bakedShadowTexture = await new THREE.TextureLoader().loadAsync(
      "/textures/ShadowBake.png"
    );
    this.bakedShadowPlane.material.map = bakedShadowTexture;
    this.bakedShadowPlane.material.transparent = true;
    this.bakedShadowPlane.material.opacity = 0.6;
    this.bakedShadowPlane.scale.set(2, 2, 1);
    this.add(this.bakedShadowPlane);

    this.add(this.baseNode);
  }

  public setDimensions(width: number, height: number) {
    const averageEdgeLength = (width + height) / 2;
    const floatingGap = Math.max(0.007, averageEdgeLength / 100);

    const printSize = new Vector2(width, height);
    const mountThickness = new Vector2(floatingGap, floatingGap); // TODO: rename this... it's not a mount anymore
    const frameThickness = new Vector2(0.009, 0.009);
    if (this.baseNode) {
      this.morphableGeometries.forEach((child) => {
        child.setDimensions(printSize, mountThickness, frameThickness); // TODO: hardwired frame and mount thickness
      });

      this.baseNode.children.forEach((baseChild) => {
        baseChild.children.forEach((child) => {
          // TODO: don't have this loop in addition to the above one... organise it all properly instead
          if (child instanceof THREE.Mesh) {
            child.geometry.attributes.position.needsUpdate = true;
          }
        });
      });

      // Calculate the baked shadow bounds to sit just within the outer bounds of the frame. The 99% accounts for imperfect dimensions in the model...
      this.baseNodeBounds = printSize
        .clone()
        .add(
          mountThickness
            .clone()
            .multiplyScalar(2)
            .add(frameThickness.clone().multiplyScalar(2))
        )
        .multiply(new THREE.Vector2(1.0, 1.01));
      this.bakedShadowPlane?.scale.set(
        (this.flipShadow ? -1 : 1) * 2 * this.baseNodeBounds.x,
        2 * this.baseNodeBounds.y,
        1
      );
    }

    // Update inner shadow intensity
    // Note: Hijacking setDimensions to respond to the change in mount state... bit of a hack
    if (this.artworkMaterial) {
      // this.artworkMaterial.aoMapIntensity = this.isMounted ? 0.1 : 1;
    }
  }

  public totalSize() {
    return this.baseNodeBounds;
  }
}

// Assumes a symmetrical shape as you look at it from the front. Symmetrical on the X axis and symmetical on the Y axis.
class MorphableGeometry {
  private originalVertices: Float32Array;
  // offsetThreshold: Vector2; // Top right corner point where you want to split the vertices. This is mirrored on all corners.

  vertices: Float32Array;

  constructor(vertices: Float32Array) {
    // console.log("CONSTRUCTOR");
    // this.offsetThreshold = offsetThreshold;
    this.originalVertices = Object.assign([], vertices);
    this.vertices = vertices;
  }

  compareWithTarget(v: Vector2, target: Vector2, margin: number) {
    if (
      (Math.abs(v.x) > target.x - margin &&
        Math.abs(v.x) < target.x + margin) ||
      (Math.abs(v.y) > target.y - margin && Math.abs(v.y) < target.y + margin)
    ) {
      return true;
    }
    return false;
  }

  // Get -1 or +1 depending on polarity
  polarity(v: Vector2) {
    const polarityX = v.x < 0 ? -1 : 1;
    const polarityY = v.y < 0 ? -1 : 1;
    return new Vector2(polarityX, polarityY);
  }

  remap(v: Vector2, base: Vector2, adjusted: Vector2, hardClamp: boolean) {
    const p = this.polarity(v);
    //vec2 result = v - (base * p) + (adjusted * p);
    const r1 = base.clone().multiply(p);
    const r2 = adjusted.clone().multiply(p);
    let result = v.clone();
    result.sub(r1);
    result.add(r2);
    if (hardClamp) {
      // console.log("result: " + JSON.stringify(result));
      // console.log("adjusted: " + JSON.stringify(adjusted));
      result.clamp(adjusted.clone().multiplyScalar(-1), adjusted);
    }
    return result;
  }

  setDimensions(
    printSize: Vector2,
    mountThickness: Vector2,
    frameThickness: Vector2
  ) {
    const printOuterVertex = new Vector2(0.287, 0.214);
    const mountOuterVertex = new Vector2(0.294, 0.22);
    const frameOuterVertex = new Vector2(0.308, 0.234);
    const vertexMargin = 0.0058;

    // console.log("printSize: " + JSON.stringify(printSize));

    // Based on the input parameters, morph the relevant vertices, along x and y axis.
    const newPrintOuterVertexAbs = printSize.clone().divideScalar(2);
    const newMountOuterVertexAbs = newPrintOuterVertexAbs
      .clone()
      .add(mountThickness);
    const newFrameOuterVertexAbs = newMountOuterVertexAbs
      .clone()
      .add(frameThickness);

    // console.log(
    //   "newPrintOuterVertexAbs: " + JSON.stringify(newPrintOuterVertexAbs)
    // );

    for (let i = 0; i < this.originalVertices.length - 2; i += 3) {
      const oX = this.originalVertices[i];
      const oY = this.originalVertices[i + 1];
      const oZ = this.originalVertices[i + 2];
      const originalPosition = new Vector2(oX, oY);

      let newPosition = new Vector2(oX, oY);
      let newZ = oZ;

      if (
        this.compareWithTarget(originalPosition, printOuterVertex, vertexMargin)
      ) {
        // Moving print outer bounds (it has no inner bounds)
        newPosition = this.remap(
          originalPosition,
          printOuterVertex,
          newPrintOuterVertexAbs,
          false
        );
        // // Bring print forwards to compensate for the absent mat. Otherwise you end up with the mat being visible squashed against the inside edge of the frame
        // if (mountThickness.length() === 0.0) {
        //   newZ += 0.0026;
        // }
      } else if (
        this.compareWithTarget(originalPosition, mountOuterVertex, vertexMargin)
      ) {
        // Moving mat outer bounds
        newPosition = this.remap(
          originalPosition,
          mountOuterVertex,
          newMountOuterVertexAbs,
          true
        );
      } else if (
        this.compareWithTarget(originalPosition, frameOuterVertex, vertexMargin)
      ) {
        // Moving frame outer bounds
        newPosition = this.remap(
          originalPosition,
          frameOuterVertex,
          newFrameOuterVertexAbs,
          false
        );
      }

      this.vertices[i] = newPosition.x;
      this.vertices[i + 1] = newPosition.y;
      this.vertices[i + 2] = newZ;
    }
  }
}

export default FloatingCanvasNode;
